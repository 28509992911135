/* Compatible with Leaflet 0.7 */
.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}
.leaflet-control-locate.active a {
  color: #2074B6;
}
.leaflet-control-locate.active.following a {
  color: #FC8428;
}
